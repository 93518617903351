import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, Subject, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { DocumentDataModel } from '../models/document-data.model';
import { AdvancedCriteria, AdvancedCriteriaResult } from '../interfaces/AdvancedCriteria';

@Injectable({
  providedIn: 'root',
})
export class ProductionViewService {
  private documentData = null;
  private resize = new Subject<void>();
  private controllerAPI: string;
  public project: string;
  public rawDocumentData: any = null;

  constructor(private http: HttpClient) { }

  getDocumentProject(folderHash: string): Observable<any> {
    var url = `${environment.portalURL}/api/Project/GetProjectName?folderHash=${folderHash}`;

    return this.http
      .get(url)
      .pipe(tap((data: string) => (this.project = data.toLowerCase())));
  }

  getDocumentData(folderHash: string, raw = false): Observable<DocumentDataModel> {
    this.controllerAPI = environment.controllerAPI;

    var url = this.controllerAPI + '/api/Document?hash=' + folderHash;

    if (this.documentData || this.rawDocumentData) {
      return raw ? of(this.rawDocumentData) : of(this.documentData);
    }

    return this.http.get(url).pipe(
      tap((data) => this.rawDocumentData = data),
      map((data) => {
        this.rawDocumentData = data;
        this.documentData = new DocumentDataModel().deserialize(data);
        this.documentData.setUnparsedData(data);
        return raw ? this.rawDocumentData : this.documentData;
      })
    );
  }

  GetCountOfManualProcessesByAdvancedCriteria(ac: AdvancedCriteria) {
    const url: string =
      environment.controllerAPI.replace('PROJECTNAME', this.project) +
      '/api/ManualProcess/GetCountOfManualProcessesByAdvancedCriteria';

    return this.http.post<number>(url, ac);
  }

  GetManualProcessDataByAdvancedCriteria(ac: AdvancedCriteria) {
    const url: string =
      environment.controllerAPI.replace('PROJECTNAME', this.project) +
      '/api/ManualProcess/GetFilteredManualProcessesByAdvancedCriteria';

    return this.http.post<AdvancedCriteriaResult>(url, ac);
  }

  async unlockEntry(folderHash): Promise<any> {
    const url: string =
      environment.portalURL +
      '/api/ManualProcess/UnlockEntry?folderHash=' +
      folderHash;

    return await this.http
      .post(url, '', { observe: 'response' })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('ERROR Unlocking Entry [MercedesService]\n', error);
        return error;
      });
  }

  unlockManualProcessEntry(folderHash: string): Observable<any> {
    const url: string =
      environment.portalURL +
      '/api/ManualProcess/UnlockEntry?folderHash=' +
      folderHash;

    return this.http.post(url, '', { observe: 'response' });
  }
}
